import app from '@/main'
import router from '@/router'
import jwtDefaultConfig from './jwtDefaultConfig'
import cookie from '@/libs/vue-cookies'
import { msgAlertBox } from '@/utils/modal'
import { responseCodesToReturn, ApiResponseCode } from '@/constants/api-response-code'
import store from '@/store'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  mngrInf = null

  constructor(axios, jwtOverrideConfig) {

    this.axiosIns = axios.create({
      // timeout: 1000,
      baseURL: process.env.VUE_APP_API_URL,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        ContentType: 'application/json',
      },
    }) 

    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    this.mngrInf = cookie.getMngrInf()

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // 로딩바 표시안할 경우 noSpinner: true
        if (!config.noSpinner) {
          store.commit('cmn/cmnStore/SET_IS_LOADING', true)
        }
        const accessToken = cookie.getAccessToken()
        // If token is present add it to request's Authorization Header
        if (accessToken) {
          config.headers.Authorization = accessToken
        }
        return config
      },
      error => {
        store.commit('cmn/cmnStore/SET_IS_LOADING', false)
        return Promise.reject(error)
      },
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      async (response) => {
        store.commit('cmn/cmnStore/SET_IS_LOADING', false)
        if (response.data 
          && (responseCodesToReturn.includes(response.data.resCd)
            || this.jwtConfig.excludedEndpoints.includes(response.config.url)
        )) {
          return response
        }
        else {
          msgAlertBox(app, response.data.resMsg)
          throw new Error(`${response.data.resMsg}(${response.data.resCd})`)
          // msgAlertBox(app, '시스템 오류입니다.</br>시스템 관리자에게 문의하십시오.')
          // throw new Error(response.data.resCd)
        }
      },
      async (error) => {
        const { config, response } = error
        const originalRequest = config

        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken()
            .then(r => {
              this.isAlreadyFetchingAccessToken = false

              cookie.saveAccessToken(r.data.resData.newAccessToken)
              this.onAccessTokenFetched(r.data.resData.newAccessToken)  
            })
            .catch(async(e) => {
              this.isAlreadyFetchingAccessToken = false
              await store.dispatch('cmn/cmnStore/initState')
              await store.dispatch('login/loginStore/logout')
              router.replace({ path: '/login/Login' })
            })
          }

          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // Make sure to assign accessToken according to your response.
              // Check: https://pixinvent.ticksy.com/ticket/2413870
              // Change Authorization header
              // originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              originalRequest.headers.Authorization = `${accessToken}`
              resolve(this.axiosIns(originalRequest))
            })
          })

          store.commit('cmn/cmnStore/SET_IS_LOADING', false)

          return retryOriginalRequest
        }

        store.commit('cmn/cmnStore/SET_IS_LOADING', false)
        console.error(error)
        msgAlertBox(app, '시스템 오류입니다.</br>시스템 관리자에게 문의하십시오.')

        return Promise.reject(error)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  } 

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refshTkn: cookie.getRefreshToken()
    },
    {
      headers: {
        Authorization: cookie.getAccessToken(),
      },
    })
  }

  getAxiosWithAuth() {
    return this.axiosIns
  }

  async updateRefreshToken(...args) {
    return this.axiosIns.post(this.jwtConfig.refreshUpdateEndpoint, ...args)
  }

  async deleteRefreshToken(...args) {
    return this.axiosIns.delete(this.jwtConfig.refreshDeleteEndpoint, ...args)
  }

  async checkRefreshToken(...args) {
    return this.axiosIns.post(this.jwtConfig.refreshCheckEndpoint, ...args)
  }

}
