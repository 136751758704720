export default {
  // Endpoints
  // loginEndpoint: '/auth/signin',
  // registerEndpoint: '/jwt/register',
  refreshEndpoint: '/cert/refresh',
  refreshUpdateEndpoint: '/cert/update',
  refreshDeleteEndpoint: '/token/delete',
  refreshCheckEndpoint: '/token/checkRefreshToken',
  // logoutEndpoint: '/jwt/logout',
  excludedEndpoints: [
    '/apicomn/fileDownload' // 파일 다운로드
  ],
  
  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  // tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  // cookieTokenKeyName: 'auth',
  // storageRefreshTokenKeyName: 'refreshToken',
  // storageUserDataKeyName: 'userData',
  // storageMenuDatakeyName: 'menuData',
  cookieAccessTokenKeyName: 'accessToken',
  cookieRefreshTokenKeyName: 'refreshToken',
  cookieOldRefreshTokenKeyName: 'oldRefreshToken',
  cookieMngrInfKeyName: 'mngrInf',
  cookieCurrentMenuKeyName: 'currentMenu',

  // jwt access token 만료 기간(30분) => 서버 설정과 동일
  // accessTokenExpireTime: '60 * 30',
  // jwt access token 만료 기간(7일) => 서버 설정과 동일
  accessTokenExpireTime: '60 * 30 * 24 * 7',
  // jwt refresh token 만료 기간(2주) => 서버 설정과 동일
  refreshTokenExpireTime: '60 * 60 * 24 * 14',
  // 관리자 정보 만료 기간(30분) => jwt access token 만료 기간과 동일
  mngrInfExpireTime: '60 * 30 * 24 * 7',
  currentMenuExpireTime: '60 * 30 * 24 * 7',
}
